.product_card {
  position: relative;
  margin-bottom: 1.5rem;

  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  font-size: 0.813rem;
  color: rgb(var(--default-text-color));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  color: rgb(var(--default-text-color));
  .close {
    background-color: #ffdedc;
    color: rgb(var(--danger));
    cursor: pointer;
    padding: 5px;
    border-radius: 4px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .image {
    // background-color: rgb(var(--light));
    background-color: white;
    margin-bottom: 0.75rem;
  }
  .rating {
    color: rgb(var(--warning));
  }
  .name {
    font-weight: 600;
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0.63rem;
  }
  .brand {
    --tw-text-opacity: 1;
    color: rgb(140 144 151 / var(--tw-text-opacity));
    font-size: 0.6875rem;
    margin-bottom: 0.5rem;
  }
}
