.footer {
  @media (max-width: 767px) {
    padding: 20px 10px !important;
    .title {
      font-size: 16px;
    }
    .flex {
      flex-direction: column;
      gap: 15px;
      .downloads {
        margin-top: 20px;
        .app {
          height: 40px;
        }
      }
    }
  }
}
