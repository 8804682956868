.product-description {
  @media (max-width: 768px) {
    .chakra-hstack,
    .chakra-vstack {
      flex-direction: column;
      align-items: start;
    }
  }
}

.attached-buttons {
  .chakra-button {
    margin-right: -9px;
    &:hover {
      background-color: #f3f6f8;
    }
  }
}

.product-container {
  .swiper {
    z-index: 0;
  }
}

.product-description-box {
  .lazy-load {
    display: none;
  }
}

.sold {
  opacity: 0.3;
  pointer-events: none;
  border-style: dashed !important;
  cursor: not-allowed;
}
