.container {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .text-right {
      font-size: 20px;
      font-weight: bold;
    }

    .header-right {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;

      p {
        color: #845adf;
      }

      .arrowIcon {
        color: #845adf;
      }

      span {
        font-weight: bold;
        color: black;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  .product-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    .slider-container {
    }
    .swiper {
      width: 100%;
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
}
