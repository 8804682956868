/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
    width: 8px; 
    height: 8px; 
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #C0C0C0;
    border-radius: 18px; 
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #A0A0A0; 
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #E0E0E0; 
    border-radius: 18px; 
  }
  