.product-listing {
  display: flex;
  flex-direction: row;
  @media (max-width: 767px) {
    padding: 0 10px;
  }

  .sidebar {
    width: 25%;
    background-color: #f8f9fa;
    padding: 20px;
    border-right: 1px solid #e0e0e0;

    .filter {
      margin-bottom: 20px;

      h3 {
        font-size: 1.2em;
        margin-bottom: 20px;
        margin-left: 10px; // Slightly increased margin-left
      }

      hr {
        margin: 0 -20px 10px -20px; // Ensuring the line touches the borders
        border: none;
        height: 1px;
        background-color: #e0e0e0;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        margin-left: 20px;

        input {
          margin-right: 10px;
        }

        label {
          font-size: 0.9em;
        }

        .product-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          gap: 20px;
          padding: 20px;
          flex-grow: 1;

          .product-card {
            height: 100%;
            background-color: #ffffff;
            border: 1px solid #e0e0e0;
            border-radius: 18px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            padding: 15px;

            .product-image {
              width: 100%;
              background-color: #f5f5f5;

              img {
                width: 100%;
              }
            }

            .product-info {
              h4 {
                font-size: 1em;
                margin-bottom: 10px;
              }

              .rating {
                font-size: 0.9em;
                color: #ffa41c;
                margin-bottom: 10px;
                font-weight: bold;
                font-size: 16px;
              }

              .price {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-size: 1em;
                margin-bottom: 10px;

                .offer-price {
                  color: #000000;
                  margin-right: 5px;
                  font-size: 20px;
                  font-weight: 600;
                }

                .original-price {
                  text-decoration: line-through;
                  color: #e0e0e0;
                  font-size: 20px;
                  font-weight: 600;
                }
              }

              .offer {
                background-color: #eaf9ff;
                color: #17a8c2;
                padding: 2px 6px;
                border-radius: 5px;
                font-size: 0.8em;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .offer-badge {
                color: #03a17f;
                font-size: 16px;
                font-weight: 600;
              }
            }
          }

          .product-card:hover {
            border: 1px solid #f41106;
          }
          .count {
            background-color: #e0e0e0;
            border-radius: 5px;
            padding: 2px 6px;
            font-size: 0.8em;
          }
        }
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .product-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 20px;
      padding: 20px;
      flex-grow: 1;

      .product-card {
        height: 100%;
        background-color: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 18px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding: 15px;

        .product-image {
          width: 100%;
          background-color: #f5f5f5;

          img {
            width: 100%;
          }
        }

        .product-info {
          h4 {
            font-size: 1em;
            margin-bottom: 10px;
          }

          .rating {
            font-size: 0.9em;
            color: #ffa41c;
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 16px;
          }

          .price {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 1em;
            margin-bottom: 10px;

            .offer-price {
              color: #000000;
              margin-right: 5px;
              font-size: 20px;
              font-weight: 600;
            }

            .original-price {
              text-decoration: line-through;
              color: #e0e0e0;
              font-size: 20px;
              font-weight: 600;
            }
          }

          .offer {
            background-color: #eaf9ff;
            color: #17a8c2;
            padding: 2px 6px;
            border-radius: 5px;
            font-size: 0.8em;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .offer-badge {
            color: #03a17f;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;

      .product-grid {
        grid-template-columns: 2fr;
      }
    }
  }
}
