.product-slider {
  .slider-container {
    width: 100%;
    height: 100%;
  }
  .swiper-button-prev,
  .swiper-button-next {
    padding: 25px !important;
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    border-radius: 15px;
    transform: scale(0.5);
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 20px;
  }
}

.home-banner {
  .slider-container {
    width: 100%;
    height: 100%;
  }
  .swiper-button-prev,
  .swiper-button-next {
    padding: 30px !important;
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    border-radius: 15px;
    transform: scale(0.5);
  }
}

._imgSliderBx {
  position: relative;
}

._shrDropIcon {
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgba(240, 240, 240, .7);
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}