.top_cat_main {
  @media (max-width: 767px) {
    margin-top: 20px;
  }
}

.title {
  @media (max-width: 767px) {
    font-size: 18px !important;
  }
}
