.categories {
  margin: 0 auto 20px;
  padding-left: 0 !important; // Remove extra padding for better alignment
  border-bottom: 1px solid #ededed;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    padding-left: 0 !important; // Keep the same for mobile
    display: none !important;
  }
}

.child_cat_list {
  position: absolute;
  background: #fff;
  box-shadow: 0px 9px 9px 0px rgba(0, 0, 0, 0.19);
  top: -19px;
  min-height: auto;
  z-index: 10;
  width: 100%;
  right: 0;
  padding: 20px 0 30px 45px; // Remove fixed padding for full-width content
  .child_cat_title {
    position: relative;
    margin-left: 15px;
    &::before {
      content: '';
      position: absolute;
      top: 5px;
      left: -15px;
      width: 3px;
      height: 15px;
      background: #f41106;
    }
  }
}
