$primary-color: #f41106;
$border-color: #ddd;
$focus-border-color: $primary-color;
$forgot-password-color: #f41106;

.recover-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  .recover-box {
    width: 300px;
    height:350px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h2 {
      margin-bottom: 10px;
      font-size: 24px;
      font-weight: 800;
    }

    .form-group {
      margin-bottom: 15px;

      .label {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .forgot-password {
          margin-left: auto;
          font-weight: bold;
          color: $forgot-password-color;
          font-size: 0.9em;
          text-decoration: none;
        }
      }

      label {
        display: block;
        text-align: left;
        // margin-bottom: 5px;
        color: #333;
        font-weight: bold;
      }

      input[type='text'],
      input[type='email'],
      input[type='password'] {
        width: 100%;
        padding: 10px;
        border: 1px solid $border-color;
        border-radius: 5px;
        transition: border-color 0.3s;
        margin-top: 5px;

        &:focus {
          border-color: $focus-border-color;
          outline: none;
        }
      }
    }

    // .remember-me {
    //   display: flex;
    //   align-items: center;
    //   .rememberme {
    //     font-weight: 100 !important;
    //   }

    //   input {
    //     margin-right: 10px;
    //   }
    // }

    button {
      width: 100%;
      padding: 10px;
      background-color: $primary-color;
      border: none;
      border-radius: 5px;
      color: white;
      font-size: 18px;
      font-weight: 400;
      height: 45px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }

    // .signup-link {
    //   margin-top: 20px;
    //   font-size: 0.9em;

    //   span {
    //     color: $primary-color;
    //     text-decoration: none;
    //     cursor: pointer;
    //   }
    // }
  }
}
