.accordion {
  .item {
    border-top: none;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 15px;
    &:last-child {
      border-bottom: none;
    }
    .button {
      font-weight: 600;
      padding: 0;
    }
    .panel {
      padding: 20px 0;
    }
  }
}
