@media (max-width: 1219px) {
  .table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .simple-table {
    min-width: 800px; // Set a minimum width for the table to enable scrolling
  }

  .simple-table thead tr th {
    font-size: 0.85rem; 
    font-weight: 600; 
    color: black !important; 
  }
}

.qty_box {
  .chakra-button {
    border-radius: 50%;
  }
}

.product_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 230px;
}
