.dropdown_box {
  background: #fff;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
  padding: 19px 26px;
  position: absolute;
  left: -90px;
  top: 27px;
}
@media (max-width: 768px) {
  .dropdown_box {
    left: -190px;
    z-index: 1;
  }
}